import { TableCell } from '@mui/material';
import { Dustbin } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { ServerErrorRed } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  DetailsModal,
  Loader,
  StyledTable,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  ToggleMenu,
  ToggleMenuItem,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import SearchableDropDown from 'src/Components/SearchableDropDown';
import { TopBar } from 'src/Components/TopBar';
import { useFetchEstablishments } from 'src/Hooks/Establishments/useFetchEstablishments';
import { useFetchFeatureFlagConfigs } from 'src/Hooks/FeatureFlags/useFetchFeatureFlagConfigs';
import { useUpdateFeatureFlagConfigs } from 'src/Hooks/FeatureFlags/useUpdateFeatureFlagConfig';
import { ROUTES } from 'src/Routes/Routes.types';
import { AgencyDetails } from './AgencyDetails';
import { AgencyDetailsProps } from './AgencyDetails/AgencyDetails.types';
import { BrandDetails } from './BrandDetails';
import { BrandDetailsProps } from './BrandDetails/BrandDetails.types';
import styles from './FeatureFlagDetails.module.scss';
import { allBrands, FeatureFlagDetailsProps } from './FeatureFlagDetails.types';
import { RegionDetails } from './RegionDetails';
import { RegionDetailsProps } from './RegionDetails/RegionDetails.types';

const FeatureFlagDetails = ({ featureFlagConfig }: FeatureFlagDetailsProps) => {
  const [localGlobally, setLocalGlobally] = useState(featureFlagConfig.enabledGlobally);
  const [localBrands, setLocalBrands] = useState(featureFlagConfig.enabledBrands);
  const [localRegions, setLocalRegions] = useState(featureFlagConfig.enabledRegions);
  const [localAgencies, setLocalAgencies] = useState(featureFlagConfig.enabledAgencies);

  const [selectedBrand, setSelectedBrand] = useState<BrandDetailsProps['item']>();
  const [selectedRegion, setSelectedRegion] = useState<RegionDetailsProps['item']>();
  const [selectedAgency, setSelectedAgency] = useState<AgencyDetailsProps['item']>();

  const { data: establishments, isLoading } = useFetchEstablishments();

  const updateFeatureFlagConfig = useUpdateFeatureFlagConfigs(featureFlagConfig.featureFlagId, {
    onSettled: () => {
      setTimeout(() => updateFeatureFlagConfig.reset(), 500);
    },
  });

  const brands = allBrands.filter(
    brand => !localBrands.some(({ brandCode }) => brandCode === brand.brandCode)
  );
  const regions = establishments?.filter(
    region => !localRegions.some(({ regionId }) => regionId === region.regionId)
  );
  const agencies = establishments
    ?.flatMap(region => region.zones.flatMap(zone => zone.agencies))
    .filter(agency => !localAgencies.some(({ agencyId }) => agencyId === agency.agencyId));

  return (
    <>
      <TopBar
        disablePerimeterSelection
        title={featureFlagConfig.featureFlagId}
        goBackTo={ROUTES.TOOLS.FEATURE_FLAGS.buildPath({})}
      >
        <Button
          variant="tertiary"
          onClick={() => {
            setLocalGlobally(featureFlagConfig.enabledGlobally);
            setLocalBrands(featureFlagConfig.enabledBrands);
            setLocalRegions(featureFlagConfig.enabledRegions);
            setLocalAgencies(featureFlagConfig.enabledAgencies);
          }}
        >
          réinitialiser
        </Button>
        <Button
          onClick={() =>
            updateFeatureFlagConfig.mutate({
              enabledGlobally: localGlobally,
              enabledBrands: localBrands,
              enabledRegions: localRegions,
              enabledAgencies: localAgencies,
            })
          }
          mutationStatus={updateFeatureFlagConfig.status}
        >
          enregistrer
        </Button>
      </TopBar>
      <div className={styles.content}>
        {isLoading ? (
          <div className={styles.illustration}>
            <Loader size="small" />
            chargement du fichier établissement
          </div>
        ) : !(regions && agencies) ? (
          <div className={styles.illustration}>
            <ServerErrorRed />
            désolé, une erreur s'est produite pendant le chargement du fichier établissement
          </div>
        ) : (
          <>
            <WithLightTitle title="activer globalement">
              <ToggleMenu
                value={localGlobally.toString()}
                onChange={value => setLocalGlobally(value === 'true')}
              >
                <ToggleMenuItem value={'true'}>activer globalement</ToggleMenuItem>
                <ToggleMenuItem value={'false'}>activer au cas par cas</ToggleMenuItem>
              </ToggleMenu>
            </WithLightTitle>
            {localBrands.length > 0 && (
              <WithLightTitle title="activé sur les marques suivantes">
                <StyledTable size="small">
                  <TableHeader>
                    <TableRow>
                      <TableHead>marque</TableHead>
                      <TableHead>date d'activation</TableHead>
                      <TableHead></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {localBrands.map(({ brandCode, activationDate }) => {
                      const brand = allBrands.find(brand => brand.brandCode === brandCode);
                      if (!brand) return null;
                      return (
                        <TableRow
                          key={brandCode}
                          isClickable={!localGlobally}
                          className={classnames(localGlobally && styles.disabled)}
                          onClick={() =>
                            !localGlobally &&
                            setSelectedBrand({
                              ...brand,
                              activationDate,
                              setActivationDate: date =>
                                setLocalBrands(brands =>
                                  brands.map(brand => {
                                    if (brand.brandCode !== brandCode) return brand;
                                    return { ...brand, activationDate: date };
                                  })
                                ),
                            })
                          }
                        >
                          <TableCell>
                            {brand.brandTitle} ({brand.brandCode})
                          </TableCell>
                          <TableCell>
                            {activationDate ? moment(activationDate).format('lll') : '-'}
                          </TableCell>
                          <TableCell>
                            <Button
                              data-full-height
                              variant="tertiary"
                              size="small"
                              disabled={localGlobally}
                            >
                              <Dustbin
                                onClick={event => {
                                  event.stopPropagation();
                                  setLocalBrands(localBrands =>
                                    localBrands.filter(
                                      ({ brandCode }) => brandCode !== brand.brandCode
                                    )
                                  );
                                }}
                              />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </StyledTable>
              </WithLightTitle>
            )}
            <SearchableDropDown
              disabled={localGlobally}
              items={brands}
              canBeReset={false}
              placeholder="ajouter une marque"
              keyValueExtractor={brand => ({
                value: brand.brandCode,
                label: `${brand.brandTitle} (${brand.brandCode})`,
                item: brand,
              })}
              onSelectItem={brand => {
                brand &&
                  setLocalBrands(localBrands =>
                    localBrands
                      .concat([{ brandCode: brand.brandCode }])
                      .sort((brandA, brandB) =>
                        allBrands.findIndex(brand => brand.brandCode === brandA.brandCode) <
                        allBrands.findIndex(brand => brand.brandCode === brandB.brandCode)
                          ? -1
                          : 1
                      )
                  );
              }}
            />
            {localRegions.length > 0 && (
              <WithLightTitle title="activé sur les régions suivantes">
                <StyledTable size="small">
                  <TableHeader>
                    <TableRow>
                      <TableHead>région</TableHead>
                      <TableHead>agences</TableHead>
                      <TableHead>date d'activation</TableHead>
                      <TableHead></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {localRegions.map(({ regionId, activationDate }) => {
                      const region = establishments?.find(region => region.regionId === regionId);
                      if (!region) return null;
                      const allAgencies = region.zones
                        .flatMap(zone => zone.agencies)
                        .sort((agencyA, agencyB) => (agencyA.agencyId < agencyB.agencyId ? -1 : 1));
                      const agencyList = allAgencies
                        .slice(0, 2)
                        .map(agency => agency.agencyId)
                        .join(', ');
                      return (
                        <TableRow
                          key={regionId}
                          isClickable={!localGlobally}
                          className={classnames(localGlobally && styles.disabled)}
                          onClick={() =>
                            !localGlobally &&
                            setSelectedRegion({
                              ...region,
                              activationDate,
                              setActivationDate: date =>
                                setLocalRegions(regions =>
                                  regions.map(region => {
                                    if (region.regionId !== regionId) return region;
                                    return { ...region, activationDate: date };
                                  })
                                ),
                            })
                          }
                        >
                          <TableCell>
                            {region.regionId} - {region.regionTitle}
                          </TableCell>
                          <TableCell>
                            {agencyList}
                            {allAgencies.length > 2 && ` et ${allAgencies.length - 2} autres`}
                          </TableCell>
                          <TableCell>
                            {activationDate ? moment(activationDate).format('lll') : '-'}
                          </TableCell>
                          <TableCell>
                            <Button
                              data-full-height
                              variant="tertiary"
                              size="small"
                              disabled={localGlobally}
                            >
                              <Dustbin
                                onClick={event => {
                                  event.stopPropagation();
                                  setLocalRegions(localRegions =>
                                    localRegions.filter(
                                      ({ regionId }) => regionId !== region.regionId
                                    )
                                  );
                                }}
                              />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </StyledTable>
              </WithLightTitle>
            )}
            <SearchableDropDown
              disabled={localGlobally}
              items={regions}
              canBeReset={false}
              placeholder="ajouter une région"
              keyValueExtractor={region => ({
                value: region.regionId,
                label: region.regionId + ' - ' + region.regionTitle,
                item: region,
              })}
              onSelectItem={region => {
                region &&
                  setLocalRegions(localRegions =>
                    localRegions
                      .concat([{ regionId: region.regionId }])
                      .sort((regionA, regionB) =>
                        +regionA.regionId.slice(1) < +regionB.regionId.slice(1) ? -1 : 1
                      )
                  );
              }}
            />
            {localAgencies.length > 0 && (
              <WithLightTitle title="activé sur les agences suivantes">
                <StyledTable size="small">
                  <TableHeader>
                    <TableRow>
                      <TableHead>agence</TableHead>
                      <TableHead>date d'activation</TableHead>
                      <TableHead></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {localAgencies.map(({ agencyId, activationDate }) => {
                      const agency = establishments
                        ?.flatMap(region => region.zones.flatMap(zone => zone.agencies))
                        .find(agency => agency.agencyId === agencyId);
                      if (!agency) return null;
                      return (
                        <TableRow
                          key={agencyId}
                          isClickable={!localGlobally}
                          className={classnames(localGlobally && styles.disabled)}
                          onClick={() =>
                            !localGlobally &&
                            setSelectedAgency({
                              ...agency,
                              activationDate,
                              setActivationDate: date =>
                                setLocalAgencies(agencies =>
                                  agencies.map(agency => {
                                    if (agency.agencyId !== agencyId) return agency;
                                    return { ...agency, activationDate: date };
                                  })
                                ),
                            })
                          }
                        >
                          <TableCell>
                            {agency.agencyId} - {agency.agencyTitle}
                          </TableCell>
                          <TableCell>
                            {activationDate ? moment(activationDate).format('lll') : '-'}
                          </TableCell>
                          <TableCell>
                            <Button
                              data-full-height
                              variant="tertiary"
                              size="small"
                              disabled={localGlobally}
                            >
                              <Dustbin
                                onClick={event => {
                                  event.stopPropagation();
                                  setLocalAgencies(localAgencies =>
                                    localAgencies.filter(
                                      ({ agencyId }) => agencyId !== agency.agencyId
                                    )
                                  );
                                }}
                              />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </StyledTable>
              </WithLightTitle>
            )}
            <SearchableDropDown
              disabled={localGlobally}
              items={agencies}
              canBeReset={false}
              placeholder="ajouter une agence"
              keyValueExtractor={agency => ({
                value: agency.agencyId,
                label: agency.agencyId + ' - ' + agency.agencyTitle,
                item: agency,
              })}
              onSelectItem={agency => {
                agency &&
                  setLocalAgencies(localAgencies =>
                    localAgencies
                      .concat([{ agencyId: agency.agencyId }])
                      .sort((agencyA, agencyB) => (agencyA.agencyId < agencyB.agencyId ? -1 : 1))
                  );
              }}
            />
          </>
        )}
      </div>

      <DetailsModal
        size="medium"
        selectedItem={selectedBrand}
        setSelectedItem={setSelectedBrand}
        DetailsComponent={BrandDetails}
      />
      <DetailsModal
        size="medium"
        selectedItem={selectedRegion}
        setSelectedItem={setSelectedRegion}
        DetailsComponent={RegionDetails}
      />
      <DetailsModal
        size="medium"
        selectedItem={selectedAgency}
        setSelectedItem={setSelectedAgency}
        DetailsComponent={AgencyDetails}
      />
    </>
  );
};

export const FeatureFlagDetailsRoute = () => {
  const { featureFlagId } = useTypedParams(ROUTES.TOOLS.FEATURE_FLAGS.FEATURE_FLAG_DETAILS);

  const { data: featureFlags, isLoading } = useFetchFeatureFlagConfigs();

  const featureFlagConfig = useMemo(
    () => featureFlags?.find(featureFlag => featureFlag.featureFlagId === featureFlagId),
    [featureFlagId, featureFlags]
  );

  return (
    <div className={styles.container}>
      {isLoading ? (
        <>
          <TopBar title={featureFlagId} goBackTo={ROUTES.TOOLS.FEATURE_FLAGS.buildPath({})} />
          <div className={styles.content}>
            <div className={styles.illustration}>
              <Loader size="large" />
              chargement des feature flags
            </div>
          </div>
        </>
      ) : !featureFlags ? (
        <>
          <TopBar title={featureFlagId} goBackTo={ROUTES.TOOLS.FEATURE_FLAGS.buildPath({})} />
          <div className={styles.content}>
            <div className={styles.illustration}>
              <ServerErrorRed />
              désolé, une erreur s'est produite
            </div>
          </div>
        </>
      ) : !featureFlagConfig ? (
        <>
          <TopBar title={featureFlagId} goBackTo={ROUTES.TOOLS.FEATURE_FLAGS.buildPath({})} />
          <div className={styles.content}>
            <div className={styles.illustration}>
              <ServerErrorRed />
              le feature flag {featureFlagId} est introuvable
            </div>
          </div>
        </>
      ) : (
        <FeatureFlagDetails featureFlagConfig={featureFlagConfig} />
      )}
    </div>
  );
};
