import moment from 'moment/moment';
import { CDII_STATUS } from './Cdii.types';
import {
  CandidateSearchActivityPlaning,
  DBCDIIStatus,
  EnumActivityPlanningDayStatus,
  OSMCommonModelDateDto,
} from 'src/Services/API';
import { CDISortBy, SortOrder } from 'src/Redux/CDICandidates/Types';
import { formatName } from 'src/Utils/formatName';

export const getEndMission = (
  missionEnd: { year?: number; month?: number; day?: number } | undefined,
  statut?: EnumActivityPlanningDayStatus
) => {
  const today = moment();
  const missionEndDate = moment(
    `${missionEnd?.year}/${missionEnd?.month}/${missionEnd?.day}`,
    'YYYY/MM/DD'
  ).add(1, 'day');
  if (
    statut === EnumActivityPlanningDayStatus.ValidatedAbsence ||
    statut === EnumActivityPlanningDayStatus.UnvalidatedAbsence ||
    statut === EnumActivityPlanningDayStatus.ValidatedAbsenceCompletedTimeTrackings ||
    statut === EnumActivityPlanningDayStatus.UnvalidatedAbsenceCompletedTimeTrackings ||
    statut === EnumActivityPlanningDayStatus.ValidatedAbsenceUncompletedTimeTrackings ||
    statut === EnumActivityPlanningDayStatus.UnvalidatedAbsenceUncompletedTimeTrackings
  )
    return CDII_STATUS.ABSENT;
  else if (today > missionEndDate || missionEnd?.year === undefined)
    return CDII_STATUS.INTERMISSION;
  else if (Math.round((missionEndDate.diff(today, 'days') / 7) * 5) <= 2)
    return CDII_STATUS.SOON_AVAILABLE;
  else return CDII_STATUS.MISSION;
};

export const getRepositionedStatus = (priorityStatus: DBCDIIStatus | undefined) => {
  switch (priorityStatus) {
    case DBCDIIStatus.available:
      return CDII_STATUS.AVAILABLE;
    case DBCDIIStatus.suggested:
      return CDII_STATUS.SUGGESTED;
    case DBCDIIStatus.processing:
      return CDII_STATUS.PROCESSING;
    default:
      return undefined;
  }
};

export const sortCdi = (
  cdii: CandidateSearchActivityPlaning[],
  sortBy: CDISortBy,
  sortOrder: SortOrder
): CandidateSearchActivityPlaning[] => {
  const today = moment();
  switch (sortBy) {
    case CDISortBy.AGENCY:
      return cdii;
    case CDISortBy.CONTRACT_END_DATE:
      const cdiiWithEndDate = cdii?.filter(
        candidate =>
          (candidate.lastContract?.realEndDate ?? candidate.lastContract?.endDate) !== undefined
      );
      return [
        ...(cdiiWithEndDate ?? []).sort((a, b) => {
          const aEndDate = a.lastContract?.realEndDate ?? a.lastContract?.endDate;
          const bEndDate = b.lastContract?.realEndDate ?? b.lastContract?.endDate;

          return sortOrder === SortOrder.ASC
            ? moment(`${aEndDate?.year}/${aEndDate?.month}/${aEndDate?.day}`, 'YYYY/MM/DD').isAfter(
                moment(`${bEndDate?.year}/${bEndDate?.month}/${bEndDate?.day}`, 'YYYY/MM/DD')
              )
              ? 1
              : -1
            : moment(
                  `${aEndDate?.year}/${aEndDate?.month}/${aEndDate?.day}`,
                  'YYYY/MM/DD'
                ).isBefore(
                  moment(`${bEndDate?.year}/${bEndDate?.month}/${bEndDate?.day}`, 'YYYY/MM/DD')
                )
              ? 1
              : -1;
        }),
        ...(cdii?.filter(
          candidate =>
            (candidate.lastContract?.realEndDate ?? candidate.lastContract?.endDate) === undefined
        ) ?? []),
      ];
    case CDISortBy.STATUT:
      return [
        ...(cdii ?? []).sort((a, b) => {
          const statutA = getEndMission(a.lastContract?.realEndDate ?? a.lastContract?.endDate);
          const statutB = getEndMission(b.lastContract?.realEndDate ?? b.lastContract?.endDate);
          const nextAvailabilityDateA = moment(
            a.activityPlanning?.find(
              day =>
                moment(day.date).format('DD/MM/YYYY') >= today.format('DD/MM/YYYY') &&
                day.status === 'Intermission'
            )?.date ?? moment().add(1, 'month')
          );
          const nextAvailabilityDateB = moment(
            b.activityPlanning?.find(
              day =>
                moment(day.date).format('DD/MM/YYYY') >= today.format('DD/MM/YYYY') &&
                day.status === 'Intermission'
            )?.date ?? moment().add(1, 'month')
          );

          const customValuesA =
            statutA === CDII_STATUS.INTERMISSION
              ? 0
              : statutA === CDII_STATUS.ABSENT
                ? 1
                : statutA === CDII_STATUS.SOON_AVAILABLE
                  ? 2
                  : statutA === CDII_STATUS.MISSION
                    ? 3
                    : 4;
          const customValuesB =
            statutB === CDII_STATUS.INTERMISSION
              ? 0
              : statutB === CDII_STATUS.ABSENT
                ? 1
                : statutB === CDII_STATUS.SOON_AVAILABLE
                  ? 2
                  : statutB === CDII_STATUS.MISSION
                    ? 3
                    : 4;
          return sortOrder === SortOrder.ASC
            ? customValuesA > customValuesB ||
              (customValuesA === customValuesB &&
                nextAvailabilityDateA.isAfter(nextAvailabilityDateB))
              ? 1
              : -1
            : customValuesA < customValuesB ||
                (customValuesA === customValuesB &&
                  nextAvailabilityDateA.isBefore(nextAvailabilityDateB))
              ? 1
              : -1;
        }),
      ];
    case CDISortBy.NEXT_AVAILABILITY:
      return [
        ...(cdii ?? []).sort((a, b) => {
          const nextAvailabilityDateA = a.activityPlanning?.find(
            day =>
              moment(day.date).format('DD/MM/YYYY') >= today.format('DD/MM/YYYY') &&
              day.status === 'Intermission'
          )?.date;
          const nextAvailabilityDateB = b.activityPlanning?.find(
            day =>
              moment(day.date).format('DD/MM/YYYY') >= today.format('DD/MM/YYYY') &&
              day.status === 'Intermission'
          )?.date;

          return sortOrder === SortOrder.ASC
            ? !nextAvailabilityDateA
              ? 1
              : !nextAvailabilityDateB
                ? -1
                : moment(nextAvailabilityDateA).isAfter(moment(nextAvailabilityDateB))
                  ? 1
                  : -1
            : !nextAvailabilityDateA
              ? -1
              : !nextAvailabilityDateB
                ? 1
                : moment(nextAvailabilityDateA).isBefore(moment(nextAvailabilityDateB))
                  ? 1
                  : -1;
        }),
      ];
  }
};

export const filterCdi = (
  cdii: CandidateSearchActivityPlaning[],
  filterString: string
): CandidateSearchActivityPlaning[] => {
  if (!filterString) return cdii;
  return cdii.filter(candidate =>
    formatName(candidate)?.toLowerCase().includes(filterString.toLowerCase())
  );
};

export const OSMCommonModelDateDtoToDate = (date?: OSMCommonModelDateDto): string => {
  return date
    ? moment(`${date?.year}/${date?.month}/${date?.day}`, 'YYYY/MM/DD').format('DD/MM/YYYY')
    : 'non renseignée';
};
