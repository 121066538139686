import {
  Calendar,
  CircleAdd,
  Parameters,
  SortDescending,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  Chip,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { getCurrentQualification } from 'src/Redux/Qualifications/Selectors';
import { getSelectedSalesphases } from 'src/Redux/Search/Selectors';
import { FilterMissionsModal } from 'src/Routes/Activity/Missions/MissionsTopBarComponent/FilterMissionsModal';
import { MissionSortMenu } from 'src/Routes/Activity/Missions/MissionsTopBarComponent/SortMissionsMenu';
import { PivotDatePicker } from './PivotDatePicker';
import { MissionsTopBarProps } from './MissionsTopBar.types';
import styles from './MissionsTopBarComponent.module.scss';
import { getCurrentCandidate } from 'src/Redux/CDICandidates/Selectors';
import moment from 'moment';
import { ROUTES } from 'src/Routes/Routes.types';
import { useFeatureFlag } from 'src/Hooks/FeatureFlags/useFeatureFlag';
import { FeatureFlag } from 'src/Services/API';
import { getSelectedAgencyConsultant } from 'src/Redux/AgencyConsultant/Selectors';

export const MissionsTopBarComponent = ({ pivotDate, setPivotDate }: MissionsTopBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const qualification = useSelector(getCurrentQualification);
  const agencyConsultant = useSelector(getSelectedAgencyConsultant);
  const candidate = useSelector(getCurrentCandidate);
  const salesphases = useSelector(getSelectedSalesphases);
  const company = useSelector(getCurrentCompany);

  const newMissionCreationScreen = useFeatureFlag(FeatureFlag.MISSION_CREATION_SHOW_NEW_SCREEN);

  const [isFilterMissionsModalOpen, setIsFilterMissionsModalOpen] = useState(false);
  const [isMissionCreationSelectionOpen, setIsMissionCreationSelectionOpen] = useState(false);

  const numberOfFilters =
    1 + // there is always a date
    +Boolean(salesphases.length) +
    +Boolean(qualification) +
    +Boolean(company) +
    +Boolean(candidate) +
    +Boolean(agencyConsultant);

  return (
    <div className={styles.container}>
      <PivotDatePicker
        trigger={
          <Chip size="large" rounded={false}>
            <Calendar variant="fill" />
            commandes en cours le {moment(pivotDate).format('DD/MM/YYYY')}
          </Chip>
        }
        pivotDate={pivotDate}
        setPivotDate={setPivotDate}
      />
      {/* TODO add search bar */}
      {/* {searchOpen || searchValue ? (
        <TypeAndEnter
          selectedValue={searchValue}
          setSelectedValue={newValue => setSearchValue(newValue)}
          placeholder="rechercher"
          hideReinitialisation
        />
      ) : (
        <Button.Tertiary.XSmall onClick={() => setSearchOpen(!searchOpen)}>
          <Lens />
        </Button.Tertiary.XSmall>
      )} */}
      <FilterMissionsModal
        isOpen={isFilterMissionsModalOpen}
        onClose={() => setIsFilterMissionsModalOpen(false)}
      />
      <Button.Tertiary.XSmall
        onClick={() => {
          setIsFilterMissionsModalOpen(true);
        }}
      >
        <Parameters />
        <div className={styles.dot}>{numberOfFilters}</div>
      </Button.Tertiary.XSmall>
      <MissionSortMenu
        trigger={
          <Button.Tertiary.XSmall>
            <SortDescending />
          </Button.Tertiary.XSmall>
        }
      />
      <Button.XSmall
        onClick={() => {
          if (newMissionCreationScreen) setIsMissionCreationSelectionOpen(true);
          else
            navigate(MODAL_ROUTES.MISSION_CREATION.route.buildPath({}), {
              state: {
                background: location,
              },
            });
        }}
        leftIcon={<CircleAdd />}
        text="créer une commande"
      />

      <Modal
        size="small"
        open={isMissionCreationSelectionOpen}
        onClose={() => setIsMissionCreationSelectionOpen(false)}
      >
        <ModalContent
          title="créer une commande"
          description="Pendant le développement de la nouvelle interface de création de commande, l'ancienne interface est toujours disponible"
        />
        <ModalActions side="right">
          <Button
            variant="secondary"
            onClick={() => {
              setIsMissionCreationSelectionOpen(false);
              navigate(MODAL_ROUTES.MISSION_CREATION.route.buildPath({}), {
                state: {
                  background: location,
                },
              });
            }}
          >
            ancienne interface
          </Button>
          <Button
            onClick={() => {
              setIsMissionCreationSelectionOpen(false);
              navigate(ROUTES.ACTIVITY.MISSIONS.CREATE.buildPath({}));
            }}
          >
            nouvelle interface
          </Button>
        </ModalActions>
      </Modal>
    </div>
  );
};
