import moment from 'moment';
import { useCallback, useMemo } from 'react';

import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { useFetchCandidatesActivityPlanning } from 'src/Hooks/Candidate/useFetchCandidatesActivityPlanning';
import { useFetchSuggestedCandidates } from 'src/Hooks/Candidate/useFetchSuggestedCandidates';
import RepositioningScreen from 'src/Routes/Talents/Cdii/Repositioning';
import { getEndMission } from 'src/Routes/Talents/Cdii/utils';
import { ROUTES } from 'src/Routes/Routes.types';
import { EnumActivityPlanningDayStatus } from 'src/Services/API';
import styles from './Repositioning.module.scss';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';

export enum DASHBOARD_MENU {
  REPOSITIONING = 'repositioning',
  REPOSITIONED = 'repositioned',
  REGIONAL = 'regional',
}

export enum CGC_DASHBOARD_MENU {
  REPOSITIONING = 'repositioning',
  REPOSITIONED = 'repositioned',
}

/**
 * TODO: La façon dont le repositionnement c'est actuellement fait c'est n'importe quoi, on charge la liste de tous les CDI de ma région pour trouver celui qu'on veut.
 * Il faudrait aller chercher les infos nécessaires juste à partir du candidateId dans le backend à la place
 */
export const RepositioningRoute = () => {
  const { candidateId } = useTypedParams(ROUTES.TALENTS.CDII.CDII_DETAIL.REPOSITIONING);
  const { data: isCGC } = useIsCGC();
  const { data: cdii, isLoading: cdiiLoading } = useFetchCandidatesActivityPlanning();
  const { data: regionCdii, isLoading: regionCdiiLoading } = useFetchSuggestedCandidates({});

  const { candidateData, dashboardSection } = useMemo(() => {
    const candidate = cdii?.find(cdii => cdii.id === candidateId);
    if (candidate)
      return {
        candidateData: candidate,
        dashboardSection: candidate.cdiiStatus
          ? DASHBOARD_MENU.REPOSITIONED
          : DASHBOARD_MENU.REPOSITIONING,
      };
    const regionCandidate = regionCdii?.find(cdii => cdii.id === candidateId);
    return { candidateData: regionCandidate, dashboardSection: DASHBOARD_MENU.REGIONAL };
  }, [cdii, candidateId, regionCdii]);

  const getMissionStatus = useCallback(
    (
      missionEnd: { year?: number; month?: number; day?: number } | undefined,
      statut?: EnumActivityPlanningDayStatus
    ) => getEndMission(missionEnd, statut),
    []
  );

  return (
    <>
      {(isCGC === false && regionCdiiLoading) || cdiiLoading ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des CDII-TT, cela peut prendre du temps
        </div>
      ) : (isCGC === false && !regionCdii) || !cdii ? (
        <div className={styles.illustration}>
          <ServerErrorRed />
          désolé, une erreur s'est produite
        </div>
      ) : !candidateData ? (
        <div className={styles.illustration}>
          <VidePasteque />
          candidat introuvable
        </div>
      ) : (
        <RepositioningScreen
          candidateId={candidateId}
          candidateData={candidateData}
          statut={getMissionStatus(
            candidateData.lastContract?.endDate,
            candidateData.activityPlanning?.find(
              day => moment(day.date)?.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
            )?.status
          )}
          dashboardSection={dashboardSection}
        />
      )}
    </>
  );
};
