import classnames from 'classnames';

import styles from './HabilitationCard.module.scss';
import { Props } from './HabilitationCard.types';
import { BadgeDeprecated } from '@randstad-lean-mobile-factory/react-components-core';

const HabilitationCard = (props: Props) => (
  <div className={classnames(styles.container, props.className)}>
    <div className={styles.habilitationIcon}>
      <div>{props.icon}</div>
      <div className={styles.text}>
        <div className={styles.title}>{props.title}</div>
        {props.subtitle && <div className={styles.subtitle}>{props.subtitle}</div>}
      </div>
    </div>
    {props.priorityId && (
      <div className={styles.rightSide}>
        {props.priorityId === 1 && (
          <BadgeDeprecated
            value="souhaité"
            className={classnames(styles.badge, styles.habilitationBadge, styles.wished)}
          />
        )}
        {props.priorityId === 2 && (
          <BadgeDeprecated
            value="important"
            className={classnames(styles.badge, styles.habilitationBadge, styles.important)}
          />
        )}
        {props.priorityId === 3 && (
          <BadgeDeprecated
            value="obligatoire"
            className={classnames(styles.badge, styles.habilitationBadge, styles.obligatory)}
          />
        )}
      </div>
    )}
  </div>
);

export default HabilitationCard;
