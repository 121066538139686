import { Filtres } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  Button,
  Checkbox,
  ModalActions,
  ModalContent,
  Radio,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import SelectMultipleQualifications from 'src/Components/Selects/SelectMultipleQualifications';
import { TemporaryWorkerStatus } from 'src/Services/API';
import { TemporaryWorkerStatusBadge } from '../../../TemporaryWorkerDetail/Components/TemporaryWorkerStatusBadge';
import { defaultAgencyFilters } from '../../../TemporaryWorkers.types';
import styles from './TemporaryWorkersAgencyFiltersModalContent.module.scss';
import {
  AvailabilityDateInterval,
  TemporaryWorkersAgencyFiltersModalContentProps,
} from './TemporaryWorkersAgencyFiltersModalContent.types';

export const TemporaryWorkersAgencyFiltersModalContent = ({
  filters,
  setFilters,
  onClose,
}: TemporaryWorkersAgencyFiltersModalContentProps) => {
  const [localQualifications, setLocalQualifications] = useState(filters.qualifications);
  const [localAvailabilityDateInterval, setLocalAvailabilityDateInterval] = useState(
    filters.availabilityDateInterval
  );
  const [localStatus, setLocalStatus] = useState(
    Object.fromEntries(
      Object.values(TemporaryWorkerStatus).map(entry => [entry, filters.status.includes(entry)])
    ) as Record<TemporaryWorkerStatus, boolean>
  );

  return (
    <>
      <ModalContent header={<Filtres />} title="filtres talents">
        <div className={styles.label}>
          quelles sont les qualifications que vous souhaitez voir en priorité ? (10 maximum)
        </div>
        <SelectMultipleQualifications
          selectedQualifications={localQualifications}
          setSelectedQualifications={setLocalQualifications}
        />

        <div className={styles.label}>à repositionner</div>
        <Radio
          checked={localAvailabilityDateInterval === AvailabilityDateInterval.SEVEN}
          onChange={() => setLocalAvailabilityDateInterval(AvailabilityDateInterval.SEVEN)}
        >
          dates de dispo comprises entre J-7 et J+7 depuis date du jour
        </Radio>
        <Radio
          checked={localAvailabilityDateInterval === AvailabilityDateInterval.FIFTEEN}
          onChange={() => setLocalAvailabilityDateInterval(AvailabilityDateInterval.FIFTEEN)}
        >
          dates de dispo comprises entre J-15 et J+15 depuis date du jour
        </Radio>

        <div className={styles.label}>statut du talent</div>
        <div className={styles.statusContainer}>
          {Object.values(TemporaryWorkerStatus).map(status => (
            <label key={status} className={styles.status}>
              <Checkbox
                checked={localStatus[status]}
                onChange={event => {
                  setLocalStatus(localStatus => ({
                    ...localStatus,
                    [status]: event.currentTarget.checked,
                  }));
                }}
              />
              <TemporaryWorkerStatusBadge status={status} />
            </label>
          ))}
        </div>
      </ModalContent>
      <ModalActions side="left">
        <Button
          variant="tertiary"
          onClick={() => {
            setLocalQualifications(defaultAgencyFilters.qualifications);
            setLocalAvailabilityDateInterval(defaultAgencyFilters.availabilityDateInterval);
            setLocalStatus(
              Object.fromEntries(
                Object.values(TemporaryWorkerStatus).map(entry => [
                  entry,
                  defaultAgencyFilters.status.includes(entry),
                ])
              ) as Record<TemporaryWorkerStatus, boolean>
            );
          }}
        >
          tout réinitialiser
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button variant="secondary" onClick={onClose}>
          annuler
        </Button>
        <Button
          onClick={() => {
            const qualifications = localQualifications;
            const status = Object.entries(localStatus)
              .filter(([_, isSelected]) => isSelected)
              .map(([status]) => status as TemporaryWorkerStatus);
            setFilters({
              qualifications,
              availabilityDateInterval: localAvailabilityDateInterval,
              status,
            });
            onClose?.();
          }}
        >
          valider
        </Button>
      </ModalActions>
    </>
  );
};
