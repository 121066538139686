import { DefaultIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import styles from './EpiButton.module.scss';
import { Props } from './EpiButton.types';
import { iconMapping } from '../../ProtectionEquipments.types';
import { WhoGaveEpi, YesNoUnknown } from 'src/Services/API';

const whoGaveItToText = {
  [WhoGaveEpi.EU]: 'fourni par EU',
  [WhoGaveEpi.RI]: 'fourni par RI',
  [WhoGaveEpi.UNKNOWN]: 'fournisseur non renseigné',
};

const inContractToText = {
  [YesNoUnknown.Yes]: 'mentionné contrat',
  [YesNoUnknown.No]: 'non mentionné contrat',
  [YesNoUnknown.Unknown]: "pas d'information sur la mention contrat",
};

const EpiButton = ({ epi, selected, onClick, isEditable }: Props) => (
  <div
    className={
      isEditable
        ? selected
          ? styles.selectedContainerEditable
          : styles.notSelectedContainerEditable
        : selected
        ? styles.selectedContainer
        : styles.notSelectedContainer
    }
    onClick={onClick}
  >
    <div className={styles.icon}>{iconMapping[epi.id] ?? <DefaultIcon />}</div>
    <div className={styles.textContainer}>
      <div className={styles.title}>{epi.label}</div>
      {selected && (
        <div className={styles.subtitle}>{`${epi.hasBeenWorn ? 'EPI porté' : 'EPI non porté'} | ${
          whoGaveItToText[epi.whoGaveIt]
        } | ${inContractToText[epi.inContract]}`}</div>
      )}
    </div>
  </div>
);

export default EpiButton;
