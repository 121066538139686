import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Button, Checkbox, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { illusReposi } from 'src/Assets_DEPRECATED';
import { useFetchAgenciesByCandidate } from 'src/Hooks/Candidate/useFetchAgenciesByCandidate';
import { useFetchBlacklistedCompaniesByCandidate } from 'src/Hooks/Candidate/useFetchBlacklistedCompaniesByCandidate';
import { useFetchTemporaryWorkerDetail } from 'src/Hooks/Candidate/useFetchTemporaryWorkerDetail';
import { useAddCandidateToMissions } from 'src/Hooks/Missions/useAddCandidateToMissions';
import { useFetchRepositioningMissions } from 'src/Hooks/Missions/useFetchRepositioningMissions';
import BlacklistedModal from 'src/Modals/BlacklistedModal';
import RepositioningValidationModal from 'src/Modals/RepositioningValidationModal/RepositioningValidationModal.component';
import SubscribeModal from 'src/Modals/SubscribeModal/SubscribeModal.component';
import { getMissionsSortOrder, getRepositioningMissionsSortBy } from 'src/Redux/Missions/Selectors';
import { RepositioningMissionsSortBy } from 'src/Redux/Missions/Types';
import { getAnalyticsUserInfo, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import MissionDetail from 'src/Routes/Activity/Missions/MissionDetail';
import { DistancePopover } from 'src/Routes/Talents/Cdii/Repositioning/DistancePopover';
import { QualificationsPopover } from 'src/Routes/Talents/Cdii/Repositioning/QualificationsPopover';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { EnumSearchMissionsBodySortOrder, Mission } from 'src/Services/API';
import { FiltersMenu } from './FiltersMenu';
import MissionCard from './MissionCard';
import styles from './RepositioningMissions.module.scss';
import { Props } from './RepositioningMissions.types';

const RepositioningMissions = ({ candidateId, source }: Props) => {
  const { inView, ref } = useInView();

  const sortBy = useSelector(getRepositioningMissionsSortBy);
  const sortOrder = useSelector(getMissionsSortOrder);
  const agencyId = useSelector(getCurrentAgency);
  const userInfo = useSelector(getAnalyticsUserInfo);

  const addToMissionMutation = useAddCandidateToMissions();

  const { data: candidateAgencies } = useFetchAgenciesByCandidate(candidateId);
  const { data: talentData } = useFetchTemporaryWorkerDetail(candidateId);
  const qualifications = talentData?.qualifications;

  const [distance, setDistance] = useState<number>();
  const [selectedQualifications, setSelectedQualifications] = useState<string[]>(
    qualifications?.map(qualification => qualification.id).filter(Boolean) ?? []
  );
  const [selectedMissions, setSelectedMissions] = useState<Mission[]>([]);
  const [missionToDisplay, setMissionToDisplay] = useState<string>();
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const [repositiongValidationModalOpen, setRepositiongValidationModalOpen] = useState(false);
  const [blacklistedModalOpen, setBlacklistedModalOpen] = useState<boolean>(false);
  const {
    data: missionsWithCount,
    fetchNextPage: fetchNextPageMission,
    isFetchingNextPage: isFetchingNextPageMission,
    isLoading: isLoadingMission,
  } = useFetchRepositioningMissions({
    qualificationIds: selectedQualifications.length > 0 ? selectedQualifications : undefined,
    agencies: [agencyId],
    candidateId: candidateId,
  });

  const { data: blacklistedCompaniesByTalent } = useFetchBlacklistedCompaniesByCandidate(
    candidateId
  );
  const sharedCompanies = useMemo(() => {
    const blacklistedCompanies =
      blacklistedCompaniesByTalent?.map(company => ({
        id: company.company?.id,
        name: company.company?.label,
      })) || [];

    const selectedMissionsCompaniesIds = selectedMissions.map(mission => mission.company?.id);

    return blacklistedCompanies
      .filter(company => selectedMissionsCompaniesIds.includes(company.id))
      .filter(company => company.id);
  }, [blacklistedCompaniesByTalent, selectedMissions]);

  const missions: Mission[] = (missionsWithCount?.pages.map(page => page.missions!) ?? [])
    .flat(1)
    .filter(mission => moment(mission.endDate).isAfter(moment()) || mission.endDate === undefined);

  useEffect(() => {
    if (inView && !isFetchingNextPageMission) {
      fetchNextPageMission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const sortedMissions = useMemo(() => {
    const filteredMissions: Mission[] =
      distance === undefined
        ? missions
        : (missions ?? []).filter((mission: any) => (mission.distanceToTT ?? 0) <= distance);
    switch (sortBy) {
      case RepositioningMissionsSortBy.DISTANCE:
        return (filteredMissions ?? []).sort((a, b) => {
          return sortOrder === EnumSearchMissionsBodySortOrder.ASC
            ? (a.distanceToTT ?? Math.pow(10, 4)) > (b.distanceToTT ?? Math.pow(10, 4))
              ? 1
              : -1
            : (a.distanceToTT ?? -1) < (b.distanceToTT ?? -1)
            ? 1
            : -1;
        });
      case RepositioningMissionsSortBy.NB_POSITIONS:
        return (filteredMissions ?? []).sort((a, b) => {
          return sortOrder === EnumSearchMissionsBodySortOrder.ASC
            ? (a.numberPositions ?? 0) > (b.numberPositions ?? 0)
              ? 1
              : -1
            : (a.numberPositions ?? 0) < (b.numberPositions ?? 0)
            ? 1
            : -1;
        });
      case RepositioningMissionsSortBy.START_DATE:
        const missionsWithStartDate = filteredMissions?.filter(
          mission => mission.startDate !== undefined
        );
        return [
          ...(missionsWithStartDate ?? []).sort((a, b) => {
            return sortOrder === EnumSearchMissionsBodySortOrder.ASC
              ? moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : moment(a.startDate).isBefore(moment(b.startDate))
              ? 1
              : -1;
          }),
          ...(filteredMissions?.filter(mission => mission.startDate === undefined) ?? []),
        ];
      case RepositioningMissionsSortBy.STATUT:
        return [
          ...(filteredMissions ?? []).sort((a, b) => {
            const aSalesPhase =
              (a.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;
            const bSalesPhase =
              (b.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;

            return sortOrder === EnumSearchMissionsBodySortOrder.ASC
              ? aSalesPhase > bSalesPhase || moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : aSalesPhase < bSalesPhase || moment(a.startDate).isBefore(moment(b.startDate))
              ? 1
              : -1;
          }),
        ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder, missionsWithCount, distance]);

  useEffect(() => {
    if (
      sortedMissions !== undefined &&
      sortedMissions.length > 0 &&
      missionToDisplay === undefined
    ) {
      setMissionToDisplay(sortedMissions[0].missionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMissions]);

  const [notSubscribedAgencies, setNotSubscribedAgencies] = useState<string[]>([]);
  useEffect(() => {
    if (!candidateAgencies || !selectedMissions) return;
    const selectedMissionsAgencyIds = Array.from(
      new Set(selectedMissions.map(mission => mission.agencyId).filter(Boolean))
    );
    const unsubscribed: string[] = selectedMissionsAgencyIds.filter(
      agencyId => !candidateAgencies.includes(agencyId)
    );

    setNotSubscribedAgencies(unsubscribed);
  }, [candidateAgencies, selectedMissions]);

  const addToMissions = useCallback(() => {
    addToMissionMutation.mutate(
      {
        candidateId,
        missionIds: selectedMissions.map(mission => mission.missionId ?? ''),
        isRepositioningTalent: true,
      },
      {
        onSuccess: () => {
          ReactGA.event(ANALYTICS_EVENT.COUNT_ORDER_TT_RATTACHEMENT, {
            ...userInfo,
            toggle: source ?? 'inconnu',
            rattachementOrderCount: selectedMissions.length,
          });
          if (notSubscribedAgencies.length > 0) {
            setSubscribeModalOpen(true);
          } else {
            setRepositiongValidationModalOpen(true);
          }
        },
      }
    );
  }, [
    addToMissionMutation,
    candidateId,
    notSubscribedAgencies.length,
    selectedMissions,
    source,
    userInfo,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Checkbox
          checked={
            sortedMissions.length === selectedMissions.length && selectedMissions.length !== 0
          }
          halfChecked={selectedMissions.length > 0}
          onClick={() => {
            selectedMissions.length === sortedMissions.length
              ? setSelectedMissions([])
              : setSelectedMissions(sortedMissions);
          }}
        />

        <Button
          size="medium"
          variant="secondary"
          disabled={selectedMissions.length === 0}
          onClick={() => {
            if (sharedCompanies.length > 0) {
              setBlacklistedModalOpen(true);
            } else {
              addToMissions();
            }
          }}
          mutationStatus={addToMissionMutation.status}
        >
          {selectedMissions.length > 1 ? 'rattacher aux commandes' : 'rattacher à la commande'}
        </Button>
        <div className={styles.spacer} />
        <DistancePopover distance={distance} setDistance={setDistance} />
        <QualificationsPopover
          allQualifications={qualifications ?? []}
          setSelectedQualifications={setSelectedQualifications}
          selectedQualifications={selectedQualifications}
        />
        <FiltersMenu />
      </div>
      {isLoadingMission ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des missions
        </div>
      ) : !missionsWithCount ? (
        <div className={styles.illustration}>
          <ServerErrorRed />
          désolé, une erreur s'est produite
        </div>
      ) : !sortedMissions.length ? (
        <div className={styles.illustration}>
          <VidePasteque />
          Il n'y a aucune commande correspondant aux qualifications sélectionnées.
          <br />
          Astuce: vérifier que les qualifications principales et secondaires sont à jour dans le
          dossier du talent
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.leftPartContainer}>
            <div className={styles.missionsContainer}>
              {sortedMissions.map(mission => {
                const selectedMissionIds = selectedMissions.map(mission => mission.missionId);
                const isSelected = selectedMissionIds.includes(mission?.missionId ?? '');
                return (
                  <MissionCard
                    key={mission.missionId}
                    companyName={mission.company?.name ?? ''}
                    serviceName={mission.service?.name}
                    startDate={mission.startDate}
                    agencyId={mission.agencyId}
                    status={mission.salesPhase?.label}
                    isChecked={isSelected}
                    isSelected={missionToDisplay === mission.missionId}
                    qualificationName={mission.qualification?.label ?? ''}
                    onClick={() => setMissionToDisplay(mission.missionId)}
                    onCheckBoxClick={() => {
                      isSelected
                        ? setSelectedMissions(
                            selectedMissions.filter(
                              missionItem => mission.missionId !== missionItem.missionId
                            )
                          )
                        : setSelectedMissions([...selectedMissions, mission]);
                    }}
                    flag={{
                      count: mission.distanceToTT,
                      className:
                        mission.distanceToTT !== undefined
                          ? mission.distanceToTT <= 60
                            ? styles.kmFlagBelow
                            : styles.kmFlagAbove
                          : styles.kmFlagNone,
                      label: 'km',
                    }}
                  />
                );
              })}
              <div ref={ref} />
            </div>
          </div>
          {selectedMissions.length >= 2 ? (
            <div className={styles.detailContainerMore}>
              <div className={styles.detailContainerTitle}>Sélection</div>
              <div className={styles.detailContainerContent}>
                {selectedMissions.length} commandes sélectionnées
              </div>
              <img
                src={illusReposi}
                alt="repositionnement-illus"
                className={styles.illusRepositioning}
              />
            </div>
          ) : (
            <div className={styles.detailContainer}>
              <MissionDetail
                isRepositioningTalent
                missionId={missionToDisplay}
                candidateId={candidateId}
              />
            </div>
          )}
        </div>
      )}
      <SubscribeModal
        open={subscribeModalOpen}
        setOpen={setSubscribeModalOpen}
        candidateId={candidateId}
        decryptedAgenciesIds={notSubscribedAgencies}
        setRepositiongValidationModalOpen={setRepositiongValidationModalOpen}
      />
      <RepositioningValidationModal
        open={repositiongValidationModalOpen}
        setOpen={setRepositiongValidationModalOpen}
        selectedMissionNumber={selectedMissions.length}
        missionId={selectedMissions[0]?.missionId}
        missionAgencyId={selectedMissions[0]?.agencyId}
        isRepositioningTalent={true}
      />
      <BlacklistedModal
        candidateId={candidateId}
        companies={sharedCompanies}
        isOpen={blacklistedModalOpen}
        setIsOpen={setBlacklistedModalOpen}
        addToMissions={addToMissions}
      />
    </div>
  );
};

export default RepositioningMissions;
