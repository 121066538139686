import { CircleCheck, Duplicata } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  DataTable,
  DetailsModal,
  Pagination,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ColumnDef, Row, Table } from '@tanstack/react-table';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useUpdateExternalDemands } from 'src/Hooks/ExternalDemands/useUpdateExternalDemands';
import { ROUTES } from 'src/Routes/Routes.types';
import {
  DisplayStatusDto,
  ExternalDemand,
  FetchExternalDemandsSortCriteria,
} from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { DemandDetails } from '../DemandDetails';
import { EventDetails } from '../EventDetails';
import { ExternalDemandElementMenu } from './ExternalDemandElementMenu';
import styles from './ExternalDemandsList.module.scss';
import {
  ExternalDemandChild,
  ExternalDemandElement,
  isDemandDto,
  isDemandDtoWithChildren,
  isDemandEvent,
  Props,
} from './ExternalDemandsList.types';
import { StatusFilter } from './StatusFilter';

export const ExternalDemandsList = ({
  data,
  isFetching,
  source,
  globalFilter,
  columnFilters,
  setColumnFilters,
  sorting,
  setSorting,
  pagination,
  setPagination,
  count,
  uniqueColumnValues,
}: Props) => {
  const showArchivedDemands = !!useMatch(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.ARCHIVED.path);

  const updateExternalDemands = useUpdateExternalDemands();

  const [selectedDemand, setSelectedDemand] = useState<ExternalDemand>();
  const [selectedEvent, setSelectedEvent] = useState<ExternalDemandChild>();

  const tableRef = useRef<Table<ExternalDemandElement>>(null);

  const getSubRows = useCallback((element: ExternalDemandElement) => {
    return isDemandDtoWithChildren(element)
      ? element.events.map(event => ({ ...event, parent: element }))
      : undefined;
  }, []);

  const isRowSelectable = useCallback((row: Row<ExternalDemandElement>) => row.depth === 0, []);
  const [rowSelection, setRowSelection] = useState({});

  const hubloColumns = useMemo(
    (): ColumnDef<ExternalDemandElement>[] => [
      {
        id: FetchExternalDemandsSortCriteria['command.dateMission'],
        header: 'date mission',
        accessorFn: element => {
          let item: Date | number | string | undefined = element.dateMission;
          if (typeof item === 'number' || typeof item === 'string') item = new Date(item);
          return item?.valueOf();
        },
        cell: ({ row, getValue }) => {
          const date = getValue() as number | undefined;
          return (
            <>
              {date !== undefined && moment(date).tz('CET').format('DD/MM/YYYY')}
              {isDemandDto(row.original) && (
                <>
                  <br />
                  {pluralFormat(row.original.events.length, 'roulement')}
                </>
              )}
            </>
          );
        },
        meta: {
          isDate: true,
        },
        filterFn: 'inNumberRange',
      },
      {
        id: FetchExternalDemandsSortCriteria['command.clientName'],
        header: 'nom client',
        accessorFn: element =>
          isDemandDto(element) ? element.clientName : element.parent.clientName,
        filterFn: 'arrIncludes',
        enableColumnFilter: Boolean(uniqueColumnValues),
      },
      {
        id: FetchExternalDemandsSortCriteria['command.service'],
        header: 'service',
        accessorFn: element => (isDemandDto(element) ? element.service : element.parent.service),
        filterFn: 'arrIncludes',
        enableColumnFilter: Boolean(uniqueColumnValues),
      },
      {
        id: FetchExternalDemandsSortCriteria['command.qualification'],
        header: 'qualifi\u00adcation',
        accessorFn: element =>
          isDemandDto(element) ? element.qualification : element.parent.qualification,
        filterFn: 'arrIncludes',
        enableColumnFilter: Boolean(uniqueColumnValues),
      },
      {
        id: FetchExternalDemandsSortCriteria['command.id'],
        header: 'num osmose',
        accessorFn: element =>
          isDemandEvent(element) ? element.parent.commandId : element.commandId,
        cell: ({ getValue }) => {
          const osmoseId = getValue() as string | undefined;
          return (
            osmoseId && (
              <Button
                variant="tertiary"
                size="medium"
                data-full-width
                data-full-height
                onClick={event => {
                  event.stopPropagation();
                  navigator.clipboard.writeText(osmoseId);
                }}
                className={styles.copyButton}
              >
                <Duplicata />
                {osmoseId}
              </Button>
            )
          );
        },
      },
      {
        id: FetchExternalDemandsSortCriteria['event.sourceId'],
        header: `num ${source.toLowerCase()}`,
        accessorFn: element =>
          isDemandEvent(element)
            ? element.sourceId
            : element.events.length === 1
            ? element.events[0].sourceId
            : undefined,
        cell: ({ getValue }) => {
          const numSource = getValue() as string | undefined;
          return (
            numSource && (
              <Button
                variant="tertiary"
                size="medium"
                data-full-width
                data-full-height
                onClick={event => {
                  event.stopPropagation();
                  navigator.clipboard.writeText(numSource);
                }}
                className={styles.copyButton}
              >
                <Duplicata />
                {numSource}
              </Button>
            )
          );
        },
      },
      {
        id: FetchExternalDemandsSortCriteria['event.limitDate'],
        header: 'date limite',
        accessorFn: element => {
          let item: Date | number | string | undefined = isDemandEvent(element)
            ? element.limitDate
            : element.events.length === 1
            ? element.events[0].limitDate
            : undefined;
          if (typeof item === 'number' || typeof item === 'string') item = new Date(item);
          return item?.valueOf();
        },
        cell: ({ getValue }) => {
          const date = getValue() as number | undefined;
          return date !== undefined && moment(date).tz('UTC').format('DD/MM/YYYY');
        },
        sortUndefined: false,
        meta: {
          isDate: true,
        },
        filterFn: 'inNumberRange',
      },
      {
        id: 'status',
        header: 'statut',
        accessorFn: element => (isDemandDto(element) ? element.status : element.parent.status),
        cell: ({ row }) => (
          <div className={styles.justifyRight}>
            <ExternalDemandElementMenu
              data-no-padding-left
              source={source}
              element={row.original}
              showArchivedDemands={showArchivedDemands}
              setSelectedDemand={setSelectedDemand}
              setSelectedEvent={setSelectedEvent}
            />
          </div>
        ),
        enableSorting: false,
        meta: { customFilter: StatusFilter },
        filterFn: 'arrIncludes',
      },
    ],
    [uniqueColumnValues, source, showArchivedDemands]
  );

  const getFacetedMinMaxValues = useCallback(
    () => (): [number, number] => [
      moment().subtract(1, 'month').valueOf(),
      moment().add(6, 'months').valueOf(),
    ],
    []
  );

  useEffect(() => tableRef.current?.setGlobalFilter(globalFilter), [globalFilter]);

  const getFacetedUniqueValues = useCallback(
    (_: Table<ExternalDemandElement>, columnId: string) => {
      if (columnId === FetchExternalDemandsSortCriteria['command.clientName'])
        return () => new Map(uniqueColumnValues?.clientNames.map(clientName => [clientName, 1]));
      if (columnId === FetchExternalDemandsSortCriteria['command.service'])
        return () => new Map(uniqueColumnValues?.services.map(service => [service, 1]));
      if (columnId === FetchExternalDemandsSortCriteria['command.qualification'])
        return () =>
          new Map(uniqueColumnValues?.qualifications.map(qualification => [qualification, 1]));
      return () => new Map<string, number>();
    },
    [uniqueColumnValues]
  );

  return (
    <div className={styles.container}>
      <div className={styles.actionRow}>
        {!showArchivedDemands && (
          <Button
            size="medium"
            className={styles.demandsButtonContainer}
            onClick={() => {
              const commandStatusToUpdate =
                tableRef.current
                  ?.getSelectedRowModel()
                  .rows.map(row =>
                    isDemandDto(row.original)
                      ? {
                          commandId: row.original.commandId,
                          displayStatus: DisplayStatusDto.archived,
                        }
                      : undefined
                  )
                  .filter(Boolean) ?? [];
              if (!commandStatusToUpdate.length) return;

              updateExternalDemands.mutate(
                { commandStatusToUpdate },
                {
                  onSuccess: () => {
                    tableRef.current?.toggleAllRowsSelected(false);
                  },
                }
              );
            }}
            disabled={Object.keys(rowSelection).length === 0 || isFetching}
            mutationStatus={updateExternalDemands.status}
          >
            <CircleCheck />
            archiver
          </Button>
        )}
        <div className={styles.spacer} />
        <Pagination
          pageCount={Math.ceil(count / pagination.pageSize)}
          currentPage={pagination.pageIndex}
          setCurrentPage={page => tableRef.current?.setPageIndex(page)}
          className={styles.pagination}
        />
      </div>

      <DataTable<ExternalDemandElement, unknown>
        size="medium"
        columns={hubloColumns}
        data={data}
        enableExpanding
        enableRowSelection={!showArchivedDemands && isRowSelectable}
        state={{
          rowSelection,
          columnFilters,
          sorting,
          pagination,
          globalFilter,
        }}
        onRowSelectionChange={setRowSelection}
        onSortingChange={setSorting}
        onColumnFiltersChange={setColumnFilters}
        getSubRows={getSubRows}
        stickyHeader
        disableMultiExpansion
        enablePagination
        manualFiltering
        manualSorting
        manualPagination
        onPaginationChange={setPagination}
        initialState={{ pagination }}
        enableAlternateBackgrounds
        filterFromLeafRows
        getFacetedMinMaxValues={getFacetedMinMaxValues}
        getFacetedUniqueValues={getFacetedUniqueValues}
        enableMultiSort={false}
        empty="aucun résultat ne correspond à votre recherche"
        ref={tableRef}
      />
      <DetailsModal
        size="medium"
        selectedItem={selectedDemand}
        setSelectedItem={setSelectedDemand}
        DetailsComponent={DemandDetails}
      />
      <DetailsModal
        size="medium"
        selectedItem={selectedEvent}
        setSelectedItem={setSelectedEvent}
        DetailsComponent={EventDetails}
        source={source}
      />
    </div>
  );
};
