import {
  Button,
  Checkbox,
  DropDown,
  ModalDeprecated,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect, useRef, useState } from 'react';
import { LateralityInjury, MaseItem } from 'src/Services/API';
import { hurtPerson } from 'src/Assets_DEPRECATED';
import styles from './LesionDescriptionModal.module.scss';
import { Props } from './LesionDescriptionModal.types';
import { useFetchInjuryNatures } from 'src/Hooks/WorkAccidentAnalysis/useFetchInjuryNatures';
import { useFetchInjuryLocation } from 'src/Hooks/WorkAccidentAnalysis/useFetchInjuryLocation';
import { useFetchInjuryLocationDetails } from 'src/Hooks/WorkAccidentAnalysis/useFetchInjuryLocationDetails';

const LesionDescriptionModal = ({ onValidate, injury, trigger, open }: Props) => {
  const ref = useRef<PopupActions>(null);

  const [isOpen, setIsOpen] = useState(false);

  const [selectedInjuryLocation, setSelectedInjuryLocation] = useState<MaseItem | null>(
    injury?.injuryLocation ?? null
  );
  const [
    selectedInjuryLocationDetails,
    setSelectedInjuryLocationDetails,
  ] = useState<MaseItem | null>(injury?.injuryLocationDetails ?? null);
  const [selectedInjuryLaterality, setSelectedInjuryLaterality] = useState<LateralityInjury | null>(
    injury?.injuryLaterality ?? null
  );
  const [selectedInjuryNatures, setSelectedInjuryNatures] = useState<MaseItem[]>(
    injury?.injuryNatures ?? []
  );

  const injuryNatures = useFetchInjuryNatures();
  const injuryLocation = useFetchInjuryLocation();
  const injuryLocationDetails = useFetchInjuryLocationDetails(selectedInjuryLocation?.id);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
  }, [open]);

  const onOpen = () => {
    if (injury) {
      setSelectedInjuryLocation(injury.injuryLocation ?? null);
      setSelectedInjuryLocationDetails(injury.injuryLocationDetails ?? null);
      setSelectedInjuryLaterality(injury.injuryLaterality ?? null);
      setSelectedInjuryNatures(injury.injuryNatures ?? []);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedInjuryLocation(null);
    setSelectedInjuryLocationDetails(null);
    setSelectedInjuryLaterality(null);
    setSelectedInjuryNatures([]);
    ref.current?.close();
  };

  return (
    <ModalDeprecated
      ref={ref}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger={trigger}
      icon={<img src={hurtPerson} alt="no-result" className={styles.image} />}
      title={`ajouter une lésion`}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          disabled={
            !selectedInjuryLocation ||
            !selectedInjuryLocationDetails ||
            !selectedInjuryLaterality ||
            selectedInjuryNatures.length === 0
          }
          onClick={() => {
            onValidate({
              injuryLocation: selectedInjuryLocation ?? undefined,
              injuryLocationDetails: selectedInjuryLocationDetails ?? undefined,
              injuryLaterality: selectedInjuryLaterality ?? undefined,
              injuryNatures: selectedInjuryNatures.map(injury => {
                return {
                  id: injury?.id ?? '',
                  label: injury?.label ?? '',
                };
              }),
            });
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <WithLightTitle title="liste des sièges">
        <DropDown
          items={injuryLocation.data?.map(value => value.label).filter(Boolean) ?? []}
          placeholder="choisir parmi la liste…"
          canBeReset={true}
          keyValueExtractor={(searchResult: string) => {
            return {
              key: searchResult,
              value: searchResult,
            };
          }}
          selectedItem={selectedInjuryLocation?.label}
          onSelectItem={(v: string | undefined) => {
            const value = injuryLocation.data?.find(item => item.label === v);
            setSelectedInjuryLocation(
              value?.id && value?.label
                ? {
                    id: value.id,
                    label: value.label,
                  }
                : null
            );
          }}
        />
      </WithLightTitle>

      {selectedInjuryLocation && (
        <WithLightTitle title="détail siège" className={styles.margin}>
          <DropDown
            items={injuryLocationDetails.data?.map(value => value.label).filter(Boolean) ?? []}
            placeholder="choisir parmi la liste…"
            canBeReset={true}
            keyValueExtractor={searchResult => {
              return {
                key: searchResult,
                value: searchResult,
              };
            }}
            selectedItem={selectedInjuryLocationDetails?.label}
            onSelectItem={(v: string | undefined) => {
              const value = injuryLocationDetails.data?.find(item => item.label === v);
              setSelectedInjuryLocationDetails(
                value?.id && value?.label
                  ? {
                      id: value.id,
                      label: value.label,
                    }
                  : null
              );
            }}
          />
        </WithLightTitle>
      )}
      {selectedInjuryLocation && selectedInjuryLocationDetails && (
        <WithLightTitle title="côté concerné (gauche/droit)" className={styles.margin}>
          <DropDown
            items={Object.values(LateralityInjury)}
            placeholder="choisir parmi la liste…"
            canBeReset={true}
            keyValueExtractor={(searchResult: string) => {
              return {
                key: searchResult,
                value: searchResult,
              };
            }}
            selectedItem={
              selectedInjuryLaterality ? LateralityInjury[selectedInjuryLaterality] : undefined
            }
            onSelectItem={(v: string | undefined) =>
              setSelectedInjuryLaterality(LateralityInjury[v as keyof typeof LateralityInjury])
            }
          />
        </WithLightTitle>
      )}
      <WithLightTitle title="lésions" className={styles.margin}>
        <div className={styles.checkboxesContainer}>
          {injuryNatures.data?.map(elem => (
            <div key={elem.id} className={styles.checkboxes}>
              <Checkbox
                checked={selectedInjuryNatures.find(injury => injury.id === elem.id) !== undefined}
                onClick={() => {
                  if (selectedInjuryNatures.find(injury => injury.id === elem.id) !== undefined) {
                    setSelectedInjuryNatures(
                      selectedInjuryNatures.filter(nature => nature.id !== elem.id)
                    );
                  } else {
                    setSelectedInjuryNatures([
                      ...selectedInjuryNatures,
                      { ...elem, id: elem.id ?? '' },
                    ]);
                  }
                }}
              />
              {elem.label}
            </div>
          ))}
        </div>
      </WithLightTitle>
    </ModalDeprecated>
  );
};

export default LesionDescriptionModal;
